import { environment } from '../config/settings'
import { legacyApiFetch } from '../helpers/ProxyApiFetch'
import { Screener } from '../model'

export const getMarketScreener = async (
  id: string,
  network: string
): Promise<Screener | undefined> => {
  return await legacyApiFetch<Screener>(
    environment.getDexGuruAPIV2Url(),
    `/tokens/${id}/screener?network=${network}`
  )
}
