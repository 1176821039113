import { environment } from '../config/settings'
import { financialFormatLowValueHighPrecision } from '../helpers/priceAndAmountHelpers'
import { MarketType, OgDynamicMetadata, OgStaticMetadata, TokenWithApprovalContext } from '../model'
import { getMarketDisplayNameBySymbols } from '../utils'

// Note that the buckets need CORS settings to allow access to the app
// https://docs.aws.amazon.com/AmazonS3/latest/userguide/cors.html

// OGP template for reference: https://gitlab.com/telekomconsalting/dexguru/ogp-generator/-/blob/master/src/templates/html.ts
export const generateDynamicMetadata = async (
  symbols: string[] = [],
  marketType: MarketType,
  priceUSD?: number
): Promise<OgDynamicMetadata | undefined> => {
  if (!priceUSD) {
    return undefined
  }

  const ticker = getMarketDisplayNameBySymbols({ symbols })

  const title = `$${financialFormatLowValueHighPrecision(
    priceUSD
  )} ${ticker} Price to USD - Live ${ticker} Charts Today at DexGuru`

  const description = `${ticker} USD PRICE (${ticker}-USD), ${ticker} market cap, history, live tradingview charts and and other vital information to help you with your cryptocurrency trading`

  return {
    title,
    description,
    ogTitle: title,
    ogDescription: description,
    twitterTitle: title,
    twitterDescription: description,
  }
}

export const generateStaticMetadata = async (
  tokenId?: string
): Promise<OgStaticMetadata | undefined> => {
  if (!tokenId) {
    return undefined
  }

  const imageUrl = `${environment.getOgpBucketUrl()}/token/${tokenId}/${tokenId}.png`

  return {
    url: `https://dex.guru/token/${tokenId}`,
    image: imageUrl,
    imageWidth: !imageUrl ? undefined : '1200',
    imageHeight: !imageUrl ? undefined : '630',
    twitterCard: !imageUrl ? undefined : 'summary_large_image',
    twitterImage: imageUrl,
  }
}

export const updateMetadata = async (
  currentToken: TokenWithApprovalContext,
  setOgMetadata: React.Dispatch<React.SetStateAction<OgDynamicMetadata | undefined>>
): Promise<void> => {
  if (currentToken?.id) {
    const metadata = await generateDynamicMetadata(
      currentToken.symbols,
      currentToken.marketType,
      currentToken.priceUSD
    )
    setOgMetadata(metadata)

    if (metadata?.title && metadata.title !== document.title) {
      document.title = metadata?.title
    }
  }
}
