import { Address, model } from '../model'
import { LimitOrder } from '../model/limitOrderModel'
import { getTokenNetworkFromId } from '../utils'
import apiClient from './ApiClient'

export const fetchLimitOrders = async (
  account: Address,
  currentTokenId: model.TokenId
): Promise<{ orders: LimitOrder[]; error?: string }> => {
  const result: LimitOrder[] = []
  let error
  const limitOrdersData = await apiClient.limitOrders.getLimitOrdersData(account, currentTokenId)

  if (limitOrdersData.responseData) {
    for (const order of limitOrdersData.responseData) {
      const events = await apiClient.limitOrders.getLimitOrderEvents(
        order.orderHash,
        currentTokenId
      )
      if (events.responseData) {
        result.push({ details: order, events: events.responseData })
      }

      if (limitOrdersData.error) {
        error = limitOrdersData.error.message
      }
    }
  }
  if (limitOrdersData.error) {
    error = limitOrdersData.error.message
  }
  return { orders: result, error }
}

export const getTokenIdsFromLimitOrderList = (
  orders: LimitOrder[],
  currentTokenId: model.TokenId
): string[] => {
  try {
    const network = getTokenNetworkFromId(currentTokenId)

    return orders
      .map((x) => `${x.details.data.makerAsset.toLowerCase()}-${network}`)
      .concat(orders.map((x) => `${x.details.data.takerAsset.toLowerCase()}-${network}`))
  } catch (e: ReturnType<Error>) {
    console.error('getTokenIdsFromLimitOrderList: ', e.message)
    return []
  }
}
