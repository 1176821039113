import { getAmmConfig } from '../config/amm'
import { environment } from '../config/settings'
import { legacyApiFetch } from '../helpers/ProxyApiFetch'
import { PairsData, PoolsData, PoolsOptions } from '../model'
import { PoolsState } from '../reducers/pools'

const defaultOptions: PoolsOptions = {
  order: 'desc',
  limit: 10,
}

const convertV2toV3Model = (data: PairsData): PoolsData => {
  return data.map((item) => {
    return {
      id: item.id || '',
      amm: getAmmConfig(item.AMM || '').id || '',
      network: item.network,
      tokenAddresses: [item.token0?.address || '', item.token1?.address || ''],
      symbols: [item.token0?.symbol || '', item.token1?.symbol || ''],
      logoURIs: [item.token0?.logoURI || '', item.token1?.logoURI || ''],
      feeTier: item.feeTier,
      liquidityNative: item.liquidityETH || 0,
      liquidityStable: item.liquidityUSD || 0,
      volume24hNative: item.volume24hETH || 0,
      volume24hStable: item.volume24hUSD || 0,
    }
  })
}

export const getPoolsV2 = async (options: PoolsOptions): Promise<PoolsState> => {
  const poolsOptions = options
    ? {
        ...defaultOptions,
        ...options,
      }
    : defaultOptions
  const response = await legacyApiFetch<{ data: PoolsState }>(
    environment.getDexGuruAPIV2Url(),
    '/pairs/',
    {
      init: {
        method: 'POST',
        body: JSON.stringify(poolsOptions),
      },
    }
  )

  return convertV2toV3Model(response?.data || [])
}

export const getPoolsV3 = async (options: PoolsOptions): Promise<PoolsState> => {
  const poolsOptions = options
    ? {
        ...defaultOptions,
        ...options,
      }
    : defaultOptions

  const response = await legacyApiFetch<{ data: PoolsState }>(
    environment.getDexGuruAPIV3Url(),
    '/pools/',
    {
      init: {
        method: 'POST',
        body: JSON.stringify(poolsOptions),
      },
    }
  )

  return response?.data || []
}

export const getPools = async (options: PoolsOptions): Promise<PoolsState> =>
  process.env.REACT_APP_POOLS_V3 === 'true' ? getPoolsV3(options) : getPoolsV2(options)
