import { defaultSettings } from '../config/settings'
import { isValidTokenId } from '../helpers/validators'
import { ComplexSettings, Settings, SettingsFields, TokenV3 } from '../model'
import { deleteDuplicatedItem } from '../utils'

const FAVORITES_STORAGE_KEY = 'favorites'
const SETTINGS_STORAGE_KEY = 'settings'
const SOUNDS_DISABLED_STORAGE_KEY = 'sounds_disabled'

export const addUniqueFavoritesToLocalStorage = (favorites: string[]): void => {
  try {
    const currentFavorites = getFavoritesFromLocalStorage()
    const uniqueFavoritesSet = new Set([...currentFavorites, ...favorites])
    const uniqueFavorites = Array.from(uniqueFavoritesSet)
    setFavoritesToLocalStorage(uniqueFavorites)
  } catch (error) {
    console.error('persistFavorites', (error as Error).message)
  }
}

export const persistFavorites = (favorites: string[]): string[] => {
  const updatedFavorites = favorites.map((favoriteId) => {
    return favoriteId.split('-')[1] === undefined ? favoriteId + '-eth' : favoriteId
  })

  const uniqueFavorites = deleteDuplicatedItem(updatedFavorites).filter((t) => !!t)

  setFavoritesToLocalStorage(uniqueFavorites)
  return uniqueFavorites
}

export const getApplicationSettings = (): ComplexSettings => {
  const favorites: string[] = getFavoritesFromLocalStorage()
  const application: Settings = getSettingsFromLocalStorage()

  return { favorites, application }
}

export const anyFavoriteMissing = (favorites: string[], tokens: TokenV3[]): boolean => {
  return favorites.some((fid) => !tokens.find((t) => t.id === fid))
}

export const filterFavorites = (favorites: string[], tokens: TokenV3[]): TokenV3[] => {
  return (
    tokens?.filter((t) => {
      return favorites.includes(t.id)
    }) || []
  )
}

export const getFavoritesFromLocalStorage = (): string[] => {
  try {
    const value = localStorage.getItem(FAVORITES_STORAGE_KEY)
    // TODO: replace to validators
    if (!value) {
      return []
    }

    const parsedValue = JSON.parse(value)
    if (!Array.isArray(parsedValue)) {
      return []
    }
    return parsedValue.filter(isValidTokenId)
  } catch (error) {
    // parsedValue === 'undefined'
    return []
  }
}

export const addFavoriteToLocalStarage = (tokenId: string): void => {
  const favoritesLS = [...getFavoritesFromLocalStorage(), tokenId]
  setFavoritesToLocalStorage(favoritesLS)
}

export const removeFavoriteToLocalStarage = (tokenId: string): void => {
  const favoritesLS = getFavoritesFromLocalStorage().filter((id) => id !== tokenId)
  setFavoritesToLocalStorage(favoritesLS)
}

export const setFavoritesToLocalStorage = (favorites: string[]): void => {
  localStorage.setItem(FAVORITES_STORAGE_KEY, JSON.stringify(favorites))
}

export const setSettingsToLocalStorage = (settings: Partial<Settings>): void => {
  localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(settings))
}

export const getSettingsFromLocalStorage = (): Settings => {
  try {
    const storedSettings = localStorage.getItem(SETTINGS_STORAGE_KEY)
    // TODO: validate settings from localStorage
    if (!storedSettings) {
      setSettingsToLocalStorage(defaultSettings)
      return defaultSettings
    }

    const parsedSettings = JSON.parse(storedSettings)
    parsedSettings.isLeftDrawerOpen = parsedSettings.isLeftDrawerOpen ?? true
    parsedSettings.isRightDrawerOpen = parsedSettings.isRightDrawerOpen ?? true

    return parsedSettings
  } catch (error) {
    console.error(error)
    return defaultSettings
  }
}

export const filterBySettingsFieldsWhitelist = (settings: Partial<Settings>): Partial<Settings> => {
  const whitelistKeys: SettingsFields[] = Object.keys(settings).filter((field) =>
    Object.keys(SettingsFields).includes(field as SettingsFields)
  ) as SettingsFields[]

  return whitelistKeys.reduce((state, currentValue) => {
    return { ...state, [currentValue]: settings[currentValue] }
  }, {})
}

export const setSoundsDisabledToLocalStorage = (value: boolean): void => {
  localStorage.setItem(SOUNDS_DISABLED_STORAGE_KEY, JSON.stringify(value))
}

export const getSoundsDisabledFromLocalStorage = (): boolean => {
  return localStorage.getItem(SOUNDS_DISABLED_STORAGE_KEY) === 'true'
}
